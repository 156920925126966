import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  isEligibleReferrer,
  Referrer,
  StorageKey,
  StorageKeyWithId,
} from './referrer';
import type { IWebStorage } from '@wix/native-components-infra/dist/src/types/types';

export interface CachedParams<T> {
  key: StorageKey | StorageKeyWithId;
  getter: () => Promise<T>;
  referrer?: Referrer;
}
const memoryStorageFromConfig = (
  controllerConfig: ControllerFlowAPI['controllerConfig'],
) => {
  return controllerConfig.platformAPIs.storage.memory;
};

export const getCached = async <T>(
  storage: IWebStorage,
  { key, referrer, getter }: CachedParams<T>,
) => {
  const value = storage.getItem(key);
  const [k] = key.split(':');
  if (value && referrer && isEligibleReferrer(referrer, k as StorageKey)) {
    return JSON.parse(value);
  }
  const val = await getter();
  storage.setItem(key, JSON.stringify(val));
  return val;
};
export const getFromMemoryStorage = async <T extends any>(
  controllerConfig: ControllerFlowAPI['controllerConfig'],
  params: CachedParams<T>,
): Promise<T> => {
  try {
    return getCached(memoryStorageFromConfig(controllerConfig), params);
  } catch (e) {
    console.error(e);
    return params.getter();
  }
};

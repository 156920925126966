import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { getMediaUploadInfo } from '@wix/ambassador-challenges-v1-participant/http';
import { IFileUploadContext } from './interfaces';
import { request } from '../../services/request';

const MEDIA_TYPES = {
  image: 'picture',
  video: 'video',
};
let itemsLoading = 0;

export const fileUploadProviderPropsMap = async function (
  flowAPI: ControllerFlowAPI,
): Promise<IFileUploadContext> {
  return {
    uploadFile: async (params) => {
      try {
        // get media platform credentials
        const mediaCredentials = (
          await request(
            flowAPI,
            getMediaUploadInfo({
              fileName: params.file.name,
              challengeId: params.challengeId,
              participantId: params.participantId,
            }),
          )
        )?.data;
        // upload file to MP
        const fileType = params.file.type.split('/')[0];

        const mediaType = MEDIA_TYPES[fileType];
        const formData = new FormData();

        formData.append('media_type', mediaType);
        formData.append('file', params.file);

        const mediaResponse = await flowAPI.httpClient.post(
          mediaCredentials.uploadUrl,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          },
        );
        itemsLoading = itemsLoading - 1;
        flowAPI.controllerConfig.setProps({
          uploadedFile: mediaResponse.data[0],
          itemsLoading,
        });
      } catch (error) {
        console.error('Failed to upload media', error);
      }
    },
    uploadedFile: null,
    itemsLoading: 0,
    setItemsLoading: (count) => {
      itemsLoading = count;
      flowAPI.controllerConfig.setProps({
        itemsLoading: count,
      });
    },
    clearUploadedFile: () => {
      flowAPI.controllerConfig.setProps({
        uploadedFile: null,
      });
    },
  };
};

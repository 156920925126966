import { challengeStyles } from '../../challengeStyles';

export interface IFont {
  label: string;
  setting: any;
  min?: number;
  max?: number;
  experiment?: string;
}

export enum IListMobileScreenFromSettings {
  Schedule = 'Schedule',
  Overview = 'Overview',
}

export enum ISidebarMobileScreenFromSettings {
  List = 'List',
  Schedule = 'Schedule',
  Overview = 'Overview',
}

export interface IFontSection {
  label: string;
  items: IFont[];
  listMobileScreen?: IListMobileScreenFromSettings;
  sidebarMobileScreen?: ISidebarMobileScreenFromSettings;
  experiment?: string;
}

export const visitorDesignSettings: IFontSection[] = [
  {
    label: 'challenge.settings.design.mobile.content-settings.title',
    items: [
      {
        label: 'challenge.settings.design.mobile.content-dividers-width',
        setting: challengeStyles.mobContentDividerWidth,
        min: 0,
        max: 10,
      },
    ],
  },
  {
    label: 'challenge.settings.design.text-settings.title',
    items: [
      {
        label: 'challenge.settings.design.challenge-name',
        setting: challengeStyles.mobChallengeNameSize,
        max: 40,
      },
      {
        label: 'challenge.settings.design.date',
        setting: challengeStyles.mobDateSize,
      },
      {
        label: 'challenge.settings.design.section-title-font',
        setting: challengeStyles.mobSectionTitleSize,
        max: 40,
      },
      {
        label: 'challenge.settings.design.font-for-number',
        setting: challengeStyles.mobCounterNumberSize,
        max: 40,
      },
      {
        label: 'challenge.settings.design.text-following-numbers',
        setting: challengeStyles.mobCounterDescriptionSize,
      },
      {
        label: 'challenge.settings.design.description',
        setting: challengeStyles.mobDescriptionSize,
      },
    ],
  },
  {
    label: 'settings.tab.design.agenda-mobile.title',
    items: [
      {
        label: 'settings.tab.design.agenda-mobile.dates',
        setting: challengeStyles.mobAgendaSectionTitleSize,
        min: 16,
        max: 40,
      },
      {
        label: 'settings.tab.design.agenda-mobile.step-name',
        setting: challengeStyles.mobAgendaStepNameSize,
        min: 16,
        max: 40,
      },
      {
        label: 'settings.tab.design.agenda-mobile.link',
        setting: challengeStyles.mobAgendaLinkSize,
        min: 12,
      },
    ],
  },
  {
    label: 'challenge.settings.design.button-design',
    items: [
      {
        label: 'challenge.settings.design.button',
        setting: challengeStyles.mobButtonSize,
      },
    ],
  },
];

export const participantSidebarDesignSettings: IFontSection[] = [
  {
    label: 'challenge.settings.design.mobile.header-text-design-title',
    items: [
      {
        label:
          'editor.challenge.settings.design.text-style.sidebar.challenge-name',
        setting: challengeStyles.mobSidebarLayoutSidebarChallengeNameFontSize,
        max: 40,
      },
      {
        label:
          'editor.challenge.settings.design.text-style.sidebar.section-name',
        setting: challengeStyles.mobSidebarLayoutSidebarSectionNameFontSize,
        max: 24,
      },
      {
        label: 'editor.challenge.settings.design.text-style.sidebar.step-name',
        setting: challengeStyles.mobSidebarLayoutSidebarStepNameFontSize,
        max: 32,
      },
    ],
    sidebarMobileScreen: ISidebarMobileScreenFromSettings.List,
  },
  {
    label: 'challenge.settings.design.mobile.body-text-design-title',
    items: [
      {
        label: 'editor.challenge.settings.design.text-style.sidebar.step-name',
        setting: challengeStyles.mobSidebarLayoutBodyStepNameFontSize,
        max: 40,
      },
      {
        label:
          'editor.challenge.settings.design.text-style.sidebar.description',
        setting: challengeStyles.mobSidebarLayoutStepDescriptionFontSize,
      },
    ],
    sidebarMobileScreen: ISidebarMobileScreenFromSettings.Schedule,
  },
  {
    label: 'challenge.settings.design.mobile.overview-design-title',
    items: [
      {
        label: 'editor.challenge.settings.design.text-style.sidebar.title',
        setting: challengeStyles.mobSectionTitleSize,
        max: 40,
      },
      {
        label:
          'editor.challenge.settings.design.text-style.sidebar.description',
        setting: challengeStyles.mobSidebarLayoutOverviewDescriptionFontSize,
      },
    ],
    sidebarMobileScreen: ISidebarMobileScreenFromSettings.Overview,
  },
];

import uuid from 'uuid';
import {
  memberWebAppOnBeforeAction,
  memberWebAppScreenOpen,
  memberWebOnAfterAction,
} from '@wix/bi-logger-challenges-member-web/v2';
import { ActionTypes, ScreenNames } from '../contexts/main/biInterfaces';

export const biBeforeAndAfter = async <T extends (...args: any) => any>(
  bi: any,
  actionTypeMaybeArray: ActionTypes | ActionTypes[],
  fn: T,
): Promise<ReturnType<T>> => {
  const actionId = uuid();
  const actionTypesList = Array.isArray(actionTypeMaybeArray)
    ? actionTypeMaybeArray
    : [actionTypeMaybeArray];

  for (const actionType of actionTypesList) {
    bi.report(
      memberWebAppOnBeforeAction({
        actionId,
        actionType,
        // screenName,
      }),
    );
  }

  const response = await fn(actionId);

  for (const actionType of actionTypesList.slice().reverse()) {
    bi.report(
      memberWebOnAfterAction({
        actionId,
        actionType,
        // screenName,
      }),
    );
  }

  return response;
};

export const biChangePage = async (bi: any, screenName: ScreenNames) => {
  await bi.report(
    memberWebAppScreenOpen({
      screenName,
    }),
  );

  await bi.updateDefaults({
    screenName,
  });
};

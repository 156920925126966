import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ActionTypes } from '../../main/biInterfaces';
import { getChallengeId } from '../../ChallengeDataProvider/challengeDataProviderPropsMap';
import { Participant } from '@wix/ambassador-challenges-v1-participant/types';
import { biBeforeAndAfter } from '../../../services/biHelpers';
import { joinParticipant } from '@wix/ambassador-challenges-v1-participant/http';
import { request } from '../../../services/request';

export async function joinWithoutOwnerApprove(
  flowAPI: ControllerFlowAPI,
  timeZone: string,
  startDate: string,
): Promise<Participant> {
  const challengeId = await getChallengeId(flowAPI);

  return (
    await biBeforeAndAfter(
      flowAPI.bi,
      ActionTypes.CHALLENGE_JOIN,
      async (actionId) => {
        return (
          await request(
            flowAPI,
            joinParticipant({
              challengeId,
              memberId: flowAPI.controllerConfig.wixCodeApi.user.currentUser.id,
              timeZone,
              startDate,
              actionId,
            }),
          )
        )?.data;
      },
    )
  ).participant;
}

import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const isMASectionInstalled = async (
  appDefinitionId: string,
  sectionId: string,
  widgetId: string,
  flowAPI: ControllerFlowAPI,
) => {
  const { site } = flowAPI.controllerConfig.wixCodeApi;
  if (flowAPI.environment.isEditor) {
    return false;
  }

  try {
    const membersPublicAPI = await site.getPublicAPI(
      '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
    );

    const sectionURL = await membersPublicAPI.getSectionUrl({
      widgetId,
      appDefinitionId,
      sectionId,
    });

    return !!sectionURL;
  } catch (err) {
    process.env.NODE_ENV !== 'test' && console.error(err);
    return false;
  }
};

// todo: remove it after use the proper fix from MA side
// https://wix.slack.com/archives/C64RX2LA3/p1694100162957869

export const extractErrorMessage = (error: any, context: string) => {
  const contextText = context ? ` (${context})` : '';
  if (!error) {
    return `[http request failed]${contextText}:  error is empty`;
  }

  if (error?.response?.config) {
    const { method = '', url = '' } = error?.response?.config ?? {};
    const status = `[${error?.response?.status}] `;

    /**
     * e.g.: in Sentry all related errors would be grouped under:
     * [http request failed] (loadUserPaidPlans): [404] POST /serverless-yoshi-challenges-web-ooi/challenges-web-ooi-98c27b84b8903351cdb6bd645fb14028/_api_/api/benefits/benefitsList.api
     */
    return `[http request failed]${contextText}: ${status}${method?.toUpperCase() ?? ''} ${url}`;
  }

  // this case should be omitted as much as it can be error.response has unique values that is prohibited to appear in Sentry error name
  // for now it's mostly as a fallback for some strange cases
  return `[http request failed]: ${JSON.stringify({
    message: error?.message,
    response: error?.response,
    context,
  })}`;
};

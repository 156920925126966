import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getPremiumStats } from '@wix/ambassador-challenges-v1-challenge-stats/http';
import { PremiumStats } from '@wix/ambassador-challenges-v1-challenge-stats/types';
import { request } from '../../../services/request';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';
import { Challenges } from '../../../editor/types/Experiments';

export async function getStats(
  flowAPI: ControllerFlowAPI,
): Promise<PremiumStats> {
  let stats: PremiumStats = null;

  try {
    const statsResponse = (await request(flowAPI, getPremiumStats({})))?.data;
    stats = statsResponse?.stats || null;
  } catch (err) {
    handleError(err);
  }

  /*
    Also during this call backend can send the letter to the owner that participant unable to view the content,
    and because of this we should limit the places where we use this method (now its using only at participant page initial render).
   */

  return stats;
}

export async function isParticipantInLockedState(
  flowAPI: ControllerFlowAPI,
): Promise<boolean> {
  const stats = (await getStats(flowAPI)) || {};
  const now = new Date().getTime();
  const gracePeriodEnd = new Date(stats.gracePeriodEnd).getTime();
  const isPremiumOOIEnabled = flowAPI.experiments.enabled(
    Challenges.enablePremium,
  );

  return (
    isPremiumOOIEnabled &&
    stats.isPremiumFeatureEnabled &&
    !stats.hasPremium &&
    (!stats.isGracePeriodEnabled || gracePeriodEnd > now) &&
    stats.activeParticipantsCount > stats.activeParticipantsLimit
  );
}

import type { IUser } from '@wix/native-components-infra/dist/src/types/types';
import {
  State as ParticipantState,
  Participant,
} from '@wix/ambassador-challenges-v1-participant/types';
import {
  CancelJoinRequestFunction,
  JoinFunction,
  LeaveTheChallengeFunction,
} from './userContextInterface';
import { IUserProviderProps } from './UserProvider';
import { UpdatedUserData } from './helpers/userContextHelpers';
import { useControllerData } from '../main/controllerContext';

// https://www.wix.com/corvid/reference/wix-users.html#LoginOptions
export interface IPromptLogin {
  mode?: 'login' | 'signup';
  lang?: string;
}

export enum UserState {
  VISITOR = 'VISITOR',
  MEMBER = 'MEMBER',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
}

export type IUserType = UserState | ParticipantState;
export type PlatformUser = Pick<IUser, 'id' | 'instance' | 'loggedIn' | 'role'>;
export interface IUserContext {
  user: PlatformUser & { email: string };
  userType: IUserType;
  isParticipantInSuspendedState?: boolean;
  isParticipantInLockedState?: boolean;
  participant?: Participant;
  promptLogin(options?: IPromptLogin): Promise<any>;
  join: JoinFunction;
  cancelJoinRequest: CancelJoinRequestFunction;
  leaveTheChallenge: LeaveTheChallengeFunction;
  incrementParticipantsCompletedStepSummary(): Promise<UpdatedUserData>;
  updateParticipant(
    participant?: Participant,
  ): Promise<Partial<IUserProviderProps>>;
  userTypeHandlers: {
    [index: string]: (userType: IUserType) => boolean;
  };
}

export const userContextDefaultValue = {
  user: null,
  userType: null,
  isParticipantInSuspendedState: false,
  isParticipantInLockedState: false,
  participant: null,
  promptLogin: null,
  join: null,
  async incrementParticipantsCompletedStepSummary(): Promise<UpdatedUserData> {
    return {};
  },
  async updateParticipant() {
    return {};
  },
  cancelJoinRequest: null,
  leaveTheChallenge: null,
  userTypeHandlers: null,
};

export function useUser(): IUserContext {
  const props = useControllerData();
  return {
    incrementParticipantsCompletedStepSummary:
      props.incrementParticipantsCompletedStepSummary,
    updateParticipant: props.updateParticipant,
    user: props.user,
    userType: props.userType,
    isParticipantInSuspendedState: props.isParticipantInSuspendedState,
    participant: props.participant,
    promptLogin: props.promptLogin,
    join: props.join,
    cancelJoinRequest: props.cancelJoinRequest,
    leaveTheChallenge: props.leaveTheChallenge,
    userTypeHandlers: props.userTypeHandlers,
  };
}

import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { handleError } from '../contexts/ErrorHandler/errorHandlerPropsMap';
import { getInvitationLink as getLink } from '@wix/ambassador-challenges-v1-challenge/http';
import { request } from '../services/request';

export const getInvitationLink = async (
  flowAPI: ControllerFlowAPI,
  challengeId: string,
  metaSiteId: string,
): Promise<string> => {
  let inviteLink = '';

  try {
    if (challengeId) {
      const {
        invitationLink: { url },
      } = (await request(flowAPI, getLink({ challengeId })))?.data;

      inviteLink = url;
    }
  } catch (error) {
    handleError({
      error,
      context: 'getInvitationLink',
      muteError: true,
      preventErrorMonitorReport: true,
    });
  }

  return (
    inviteLink || `https://apps.wix.com/place-invites/join-lp/${metaSiteId}`
  );
};

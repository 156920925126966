import {
  benefitsList,
  IBenefitsListResponse,
} from '../api/benefits/benefitsList.api';
import { handleError } from '../contexts/ErrorHandler/errorHandlerPropsMap';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import {
  BenefitWithPlanInfo,
  Benefit,
  PlanInfo,
  PlanStatus,
} from '@wix/ambassador-pricing-plan-benefits-server/types';

const transformUserPlan = (
  plan: PublicPlan,
  planInfo: PlanInfo,
  benefit: Benefit,
) => ({
  id: planInfo.id,
  details: {
    id: planInfo.id,
    name: planInfo.name,
    description: plan?.description || '',
  },
  challenges: benefit.resourceIds,
  paymentOptions: {
    price: {
      amount: plan?.pricing?.price?.value,
      currency: plan?.pricing?.price?.currency,
    },
    additionalFees: plan.pricing?.feeConfigs?.map(({ fee }) => fee) ?? [],
    recurring: !!plan?.pricing.subscription,
    validFor: {
      forever: false,
      period: {
        amount: plan?.pricing?.subscription?.cycleDuration?.count,
        unit: plan?.pricing?.subscription?.cycleDuration?.unit,
      },
    },
  },
  visible: planInfo.visible || false,
});

export const loadUserPaidPlans = async (httpClient: IHttpClient) => {
  try {
    const paidPlansWithBenefits: IBenefitsListResponse = (
      await httpClient.request(benefitsList())
    )?.data;
    const benefitsActive: BenefitWithPlanInfo[] =
      paidPlansWithBenefits?.benefits?.filter(
        ({ planInfo }) => planInfo.status === PlanStatus.ACTIVE,
      ) || [];

    return benefitsActive
      .map(({ planInfo, benefit }) => {
        const plan = paidPlansWithBenefits.paidPlans.find(({ id }) => {
          return id === planInfo.id;
        });

        return plan ? transformUserPlan(plan, planInfo, benefit) : null;
      })
      .filter((plan) => !!plan);
  } catch (error) {
    handleError({ error, context: 'loadUserPaidPlans' });
    console.error(error);
  }
};
